import { useState, useEffect } from 'react';
import {  createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

export default function IndexPageIntro() {
    return(
                    <Box 
                        width="100%"
                        bgcolor="darkgreen.main"
                        variant="gradient"
                        sx={{
                            display: "grid",
                            placeItems: "center",
                            background: '#122418',
                        }}
                    >
                        <Grid container maxWidth="lg" spacing={2}  mt={5}>
                            <Grid item xs={12} lg={12} >
                                <Typography variant="h4" component="h3" my={1} color="grey.200" align="center">
                                    Cloud Security Fail-Safe
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container maxWidth="lg" spacing={2}>
                            <Grid item xs={0} lg={3} />
                            <Grid item xs={12} lg={6}>
                                <Typography variant="body1"  color="grey.200"  mb={4} p={2}>
                                    TrawlAI's security intelligence platform adds a new dimension of security to your cloud services, 
                                    reducing the time to detect <Typography display="inline" sx={{ fontStyle: 'italic' }}> and </Typography> address breaches to <Typography  display="inline" sx={{ fontWeight: "bold" }}>mere minutes</Typography>.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container maxWidth="lg" spacing={2} mb={6}>
                            <Grid item xs={0} md={1} />
                            <Grid item xs={12} md={4}>
                                <Box
                                    minHeight="100%"
                                    bgcolor="#0f4d2d" 
                                    p={4}
                                    mt={4}
                                >
                                    <Typography variant="h6" color="white.main">Breaches are expensive</Typography>
                                    <Typography variant="h4" mt={2} color="grey.400">$5.28 Million USD</Typography>
                                    <Typography variant="body2" color="white.main" mt={3}>
                                        Organizations with high-level of security system complexity had an average cost of $5.28 Million USD, which is
                                        $830,000 USD( 17.1% ) more than the typical cost of a data breach.*
                                    </Typography>

                                </Box>
                            </Grid>
                            <Grid item xs={0} md={2} />
                            <Grid item xs={12} md={4}>
                                <Box
                                    minHeight="100%"
                                    bgcolor="#0f4d2d" 
                                    p={4}
                                    mt={4}
                                >
                                    <Typography variant="h6" color="white.main">Did you know?</Typography>
                                    <Typography variant="h4" mt={2} color="grey.400">27%</Typography>
                                    <Typography variant="body2" color="white.main" mt={3}>
                                        Percentage of breaches that were disclosed by the attacker as part of a ransomware attack. On average, they cost nearly 20% more.*
                                    </Typography>

                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container maxWidth="lg" spacing={2} mb={4}>

                            <Grid item xs={0} md={2} />
                            <Grid item xs={12} md={4}>
                                <Typography variant="caption" color="white.main" sx={{ fontStyle: 'italic', fontSize: ".7em" }}>* IBM Security's Cost of a Data Breach</Typography>
                            </Grid>
                        </Grid>
                        
                        {/* 
                        This year, it took nearly 11 months (328 
                        days) to identify and contain data breaches 
                        resulting from stolen or compromised 
                        credentials, on average, and about 10 
                        months (308 days) to resolve breaches 
                        that were initiated by a malicious insider.

                        Only one-third of breaches were identified by 
                        the orgranization's internal security teams 
                        and tools.

                        Twenty seven percent of breaches were disclosed
                        by the attacker as part of a ransomware attack.
                        These attacks cost nearly 20% more, an average
                        of $5.23M

                        Organizations with 
                        a high level of security system complexity 
                        had a USD 5.28 million average cost, for 
                        a difference of USD 830,000 or 17.1% 
                        compared to the average cost of a data 
                        breach. 
                        
                        The cost of a data breach due to a business 
                        partner supply chain compromise averaged 
                        USD 4.76 million, which was USD 530,000 
                        or 11.8% higher than the USD 4.23 million 
                        average cost of a data breach that was due 
                        to another cause.

                        Critical infrastructure organizations 
                        included those in the financial 
                        services, industrial, technology, energy, 
                        transportation, communication, healthcare, 
                        education and public sector industries. 
                        These organizations incurred data breach 
                        costs that were USD 1.26 million higher 
                        than the average cost of USD 3.78 million 
                        for organizations in other industries, a 
                        difference of 28.6%.

                        Organizations with extensive use of security AI 
                        and automation identified and contained a data 
                        breach 108 days faster than organizations 
                        with no use.

                        Organizations with 
                        MSSPs experienced a 21% shorter 
                        breach lifecycle.

                         - IBM Security, Cost of a Data Breach 2023


                        Nearly two-thirds (62%) of respondents
                        feel that most native collaboration tool security 
                        is insufficient to meet their needs. 
                        Almost as many (57%) know their own 
                        company’s cybersecurity defenses are 
                        not capable enough to cope with the 
                        additional risks posed by 
                        these platforms
                         
                        94% of companies think they need stronger 
                        protections for their MS 365 and Google 
                        Workspace applications

                        The layer of security provided by 
                        MS 365 and Google Workspace 
                        is too thin— at least 
                        according to 94% of 
                        SOES respondents. 

                        In a world where nearly half of 
                        malicious email attachments are 
                        MS 365 files, additional layers of 
                        protection are needed

                        Mimecast's State of Email Security 2023
                        */}
                    </Box>
    )
}