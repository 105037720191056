import { useState, useEffect } from 'react';
import {  createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CountUp from 'react-countup';

export default function CounterCard({ color, count, title, description, ...rest }) {
    return (
        <Box p={2} textAlign="center" lineHeight={1}>
            <Typography variant="h2"  color={color} textGradient>
                <CountUp end={count} duration={1} {...rest} />
            </Typography>
            { title && (
                <Typography variant="h5" mt={2} mb={1} color="white.main">
                    {title}
                </Typography>
            )}
            {description && (
                <Typography variant="bod2" color="white.main">
                    {description}
                </Typography>
            )}
        </Box>
    )
}