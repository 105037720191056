import { useState, useEffect } from 'react';
import {  createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import colors from "assets/theme/base/colors";
import {Outlet} from 'react-router-dom';

const defaultTheme = createTheme({
    palette: {...colors},
    typography: {
      button: {
        textTransform: 'none'
      },
    },
    img: {
        filter: "brightness(50%)",
    },
}); 


export default function BlogMain() {
    return (
          <Outlet />
    )
}
