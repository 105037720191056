import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import trawlLogo from "assets/images/logos/trawl logo large 2022.png"
import Copyright from 'components/Copyright/Copyright';
import colors from "assets/theme/base/colors";

const defaultTheme = createTheme({
  palette: {...colors}
});

export default function NotFound() {



  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
            <img src={trawlLogo} alt="TrawlAI" width="450px" />
          <Typography component="h1" variant="h1" mt={8}>
            404
          </Typography>
          <Typography component="h1" variant="h4" mt={4}>
            Page Not Found
          </Typography>
          <Link onClick={() => nav(-1)} href="#" color="darkgreen.main"><Typography component="h1" variant="h6">Go Back</Typography></Link>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}