import { useEffect} from "react";
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import { useSearchParams } from 'react-router-dom';
import uberpostimg from 'assets/images/blogs/uberhacktitleimage.jpg';
import loginanompostimg from 'assets/images/blogs/login-anomalies.jpg';
import infrapostimg from 'assets/images/blogs/reactor.jpg';
import silentSPimg from 'assets/images/blogs/SharePoint.jpg';
import UberCompromise from "./Posts/uber-aa-email-compromise";
import LoginAnomalies from "./Posts/detecting-login-anomalies-m-365";
import CriticalInfrastructure from "./Posts/critical-infrastructure-supply-chain-attacks";
import SilentDataExfiltration from "./Posts/silent-data-exfiltration";
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';
import colors from "assets/theme/base/colors";


const sections = [
  { title: 'Technology', url: '#' },
  { title: 'Design', url: '#' },
  { title: 'Culture', url: '#' },
  { title: 'Business', url: '#' },
  { title: 'Politics', url: '#' },
  { title: 'Opinion', url: '#' },
  { title: 'Science', url: '#' },
  { title: 'Health', url: '#' },
  { title: 'Style', url: '#' },
  { title: 'Travel', url: '#' },
];

const mainFeaturedPost = {
  title: 'Critical Infrastrucutre and Supply Chain Attacks',
  date: 'Jan 24, 2023',
  description:
    "Attacks against critical infrastructure by state-sponsored hackers is expected to increase in 2023",
  image: infrapostimg,
  imageText: 'Critical Infrastructure and Supply Chain Attack Vulnerabilities',
  linkText: 'Continue reading…',
};

const featuredPosts = [
  { title: 'Silent Data Exfiltration in SharePoint Online', 
    link: 'silent-data-exfiltration',
    date: 'April 12, 2024',
    description: 'Attackers stealing SharePoint data undetected by SIEMs',
    image: silentSPimg,
    imageText: 'Attackers stealing SharePoint data undetected by SIEMs',
    linkText: 'Continue reading...',
  },
  {
    title: 'Critical Infrastructure and Supply Chain Attacks',
    link: 'critical-infrastructure-supply-chain-attacks',
    date: 'Jan 24, 2023',
    description:
      "Attacks against critical infrastructure by state-sponsored hackers is expected to increase in 2023",
    image: infrapostimg,
    imageText: 'Critical Infrastructure and Supply Chain Attack Vulnerabilities',
    linkText: 'Continue reading…',
  },
  {
    title: 'Uber Social Engineering Attack',
    link: 'uber-aa-email-compromise',
    date: 'Sep 20, 2022',
    description:
      'Large enterprise companies are having a bad week after announcing responses to email compromise attacks',
    image: uberpostimg,
    imageLabel: 'Large enterprise companies are having a bad week after announcing responses to email compromise attacks',
  },
  {
    title: 'Detecting Login Anomalies in Microsoft 365',
    link: 'detecting-login-anomalies-m-365',
    date: 'Feb 14, 2022',
    description:
      'How attackers are bypassing MFA and what you can do to prevent and detect exploits',
    image: loginanompostimg,
    imageLabel: 'How attackers are bypassing MFA and what you can do to prevent and detect exploits',
  },
];


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme({
  palette: {...colors},
  typography: {
    button: {
      textTransform: 'none'
    },
  },
  img: {
      filter: "brightness(50%)",
  },
}); 


export default function Blog() {
  useEffect(() =>{
    window.scrollTo(0,0)
 })
  /* let [searchParams, setSearchParams] = useSearchParams();
  let blog = searchParams.get("blog")
  let display;
  let bg;
  let title;
  if ( blog === "detecting-login-anomalies-m-365") {
    document.title = 'Detecting Login Anomalies in Microsoft 365';
    display = <LoginAnomalies />
  } else if (blog === "uber-aa-email-compromise") {
    document.title = 'Uber & American Airlines fall victim to Email based attack';
    display = <UberCompromise />
  } else if (blog === "critical-infrastructure-supply-chain-attacks") {
    document.title = 'Cyber-attacks on critical infrastructure expected to increase in 2023 ';
    display = <CriticalInfrastructure />
  } else if (blog === "silent-data-exfiltration") {
    document.title = 'Methods discovered to steal data while evading detection'
    display = <SilentDataExfiltration />
  } else {
    display = null;

  } */
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />

      <Container maxWidth="lg">
        <Header title="Blog" sections={sections} />
        <main>
          <Grid container spacing={4}>
            {featuredPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} />
            ))}
          </Grid>
          
        </main>
      </Container>
    </ThemeProvider>
  );
}
