import { useState, useEffect } from 'react';
import {  createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';import colors from "assets/theme/base/colors";
import dataProtect from 'assets/images/illustrations/data-protection.png'
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CountUp from 'react-countup';
import dashImage from "assets/images/platform/dashboard.png";
import Icon from "@mui/material/Icon";
import sharepointGraph from "assets/images/platform/sharepoint_file_graph.jpg";
import cloudProtection from "assets/images/platform/cloud_asset_protection.png";
import Copyright from 'components/Copyright/Copyright';
import smallLogo from 'assets/images/logos/trawl logo 2022 small.png';
import {Link} from 'react-router-dom';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import InsightsIcon from '@mui/icons-material/Insights';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';


export default function HowItWorks() {
    return (
                <Grid item xs={12} id="howitworks">
                    <Box 
                        minHeight="40rem"
                        width="100%"
                        bgcolor="darkgreen.main"
                        variant="gradient"
                        sx={{
                            backgroundSize: "cover",
                            backgroundPosition: "top",
                            display: "grid",
                            placeItems: "center",
                            background: 'linear-gradient( #122418, #07331c)',
                        }}
                    >
                        <Grid container maxWidth="xl" spacing={4}  >
                            <Grid item xs={12} textAlign="center">
                                <Typography variant="h2" mb={1} mt={6} color="grey.200">
                                    How It Works
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container maxWidth="xl" spacing={4} justifyContent="center" mt={4}>
                                <Grid item lg={1} />
                                <Grid item xs={12} lg={3}>
                                    <Box lineHeight={1}>
                                        <CardContent>
                                            <Typography color="trawlgreen.main"><CloudDownloadIcon style={{ fontSize: 60 }}/></Typography>

                                            <Typography variant="h4" component="div" color="grey.200" mt={2} mb={4} fontWeight="medium">
                                                Collect
                                            </Typography>
                                            <Typography paragraph sx={{fontSize: "1.3em"}} color="grey.200" textAlign="left">
                                                Continuously collect resource, graph, and log data from cloud services to create custom, unique data points for each tenant and user.
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Box lineHeight={1}>
                                        <CardContent>
                                            <Typography color="trawlgreen.main"><InsightsIcon style={{ fontSize: 60 }}/></Typography>

                                            <Typography variant="h4" component="div" color="grey.200" mt={2} mb={4} fontWeight="medium">
                                                Analyze
                                            </Typography>
                                            <Typography paragraph sx={{fontSize: "1.3em"}} color="grey.200" textAlign="left">
                                                TrawlAI analyzes data from cloud providers, and our own proprietary markers, to determine if activity is anomalous.
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Box lineHeight={1}>
                                        <CardContent>
                                            <Typography color="trawlgreen.main"><NotificationImportantIcon style={{ fontSize: 60 }}/></Typography>

                                            <Typography variant="h4" component="div" color="grey.200" mt={2} mb={4} fontWeight="medium">
                                                Notify
                                            </Typography>
                                            <Typography paragraph sx={{fontSize: "1.3em"}} color="grey.200" textAlign="left">
                                                If the anomalous activity is determined to fit the pattern of an attack or account takeover, notifications are sent to prevent damage.
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                </Grid>                                
                        </Grid>
                        <Grid container maxWidth="xl" spacing={4}  >
                            <Grid item xs={12} textAlign="center">
                                <Typography variant="h4" mb={1} mt={6} color="grey.200">
                                    For Microsoft 365
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container maxWidth="xl" spacing={4} justifyContent="center" mt={4} mb={6}>
                            <Grid item xs={12} lg={6} p={2} ml={1}>
                              
                                <Typography paragraph sx={{ fontSize: "1.3em"}} color="grey.200" mb={4}>
                                    With new and dificult to detect exploits being used against Microsoft's cloud office suite of services, 
                                    prevention methods are hard to maintain. 
                                    <Typography paragraph sx={{ fontSize: "1em" }} color="warning.main" display="inline">TrawlAI </Typography>
                                    quickly discovers when accounts and services have been exploited so remediation can begin immediately. 
                                    Our technology reduces the time to discover these  exploits from an average of 
                                    <Typography paragraph sx={{ fontSize: "1em", fontStyle: 'italic'}} color="error.main" display="inline"> eight months </Typography> to mere minutes.
                                   
                                </Typography>

                                <Link to="/products/ms365" >
                                    <Button 
                                        id="learnmore-button"
                                        disableElevation
                                        color="white"
                                        key="learnmore"
                                        variant="contained">
                                            <Typography sx={{ fontSize: "1.3em" }} color="black.light">Learn More</Typography>
                                    </Button>
                                </Link>
                            </Grid>
                            
                        </Grid>
                    </Box>
                    <Box 
                    minHeight="30rem"
                    width="100%"
                    bgcolor="darkgreen.main"
                    variant="gradient"
                    sx={{
                        backgroundSize: "cover",
                        backgroundPosition: "top",
                        display: "grid",
                        placeItems: "center",
                        background: '#0f4d2d',
                    }}
                    >
                        <Grid container maxWidth="xl" spacing={4}   >
                            <Grid item xs={12} textAlign="center">
                                <Typography variant="h4" mb={1} mt={6} color="grey.200">
                                    For Cloud Infrastructure
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container maxWidth="xl" spacing={4} justifyContent="center" mt={4} mb={5}>
                            
                                <Grid item xs={12} lg={6} p={2} ml={1}>
                                
                                    <Typography paragraph sx={{ fontSize: "1.3em"}} color="grey.200" mb={4}>
                                        Businesses of all types and sizes rely on Microsoft Azure and Amazon AWS to run compute workloads. 
                                        These services are a primary target for attackers for that reason. As with our Cloud Productivity protection, 
                                        <Typography paragraph sx={{ fontSize: "1em" }} color="warning.main" display="inline">TrawlAI </Typography> collects meta-data 
                                        from these providers, builds our own proprietary data points, and analyzes active services to determine if an account has been 
                                        compromised.
                                    </Typography>
                                    <Typography paragraph sx={{ fontSize: "1.3em" }} color="grey.200" mb={4}>
                                    Protect your cloud assets with intelligent, automated security from <Typography paragraph sx={{ fontSize: "1em" }} color="warning.main" display="inline">TrawlAI </Typography>.
                                    </Typography>
                                    <Link to="/products/azure" >
                                    <Button 
                                        id="learnmore-button"
                                        disableElevation
                                        color="white"
                                        key="learnmore"
                                        variant="contained">
                                            <Typography sx={{ fontSize: "1.3em" }} color="black.light">Learn More</Typography>
                                    </Button>
                                </Link>
                                </Grid>
                        </Grid>
                    </Box>
                </Grid>
    )
}