import Grid from '@mui/material/Grid';
import ContactInline from "components/ContactForm/ContactForm"
import colors from "assets/theme/base/colors";
import {  createTheme, ThemeProvider } from '@mui/material/styles';
import { useEffect } from "react";

const defaultTheme = createTheme({
    palette: {...colors},
    typography: {
      button: {
        textTransform: 'none'
      },
    },
    img: {
        filter: "brightness(50%)",
    },
}); 


export default function ContactUs() {
    useEffect(() =>{
        document.title = 'Contact TrawlAI - Securing the cloud through early detection';
        window.scrollTo(0,0)
     })
    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container maxWidth="lg" spacing={2} justifyContent="center" sx={{ mx: "auto" }} pb={2} >
                <ContactInline />
            </Grid> 
        </ThemeProvider>
    )
}