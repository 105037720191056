import { useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';

import { Fragment } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Verified } from "@mui/icons-material";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import headerImage from 'assets/images/blogs/login-anomalies.jpg';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';


function LoginAnomalies() {
    const docTitle  = 'Detecting Login Anomalies in Microsoft 365';
    useEffect(() =>{
        document.title = docTitle;
     })
  return (
    <>

        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12} md={9} lg={9} mt={4}>
                    <Breadcrumbs aria-label="breadcrumb" >
                        <Link href="/blog/index" ><Typography color="darkgreen.main">Blog Home</Typography></Link>
                    </Breadcrumbs>
                </Grid>
            </Grid> 
            <Grid container justifyContent="center" alignItems="center" mb={5} mt={5}>
                <Grid item xs={12} md={10} textAlign="center">
                    <Typography variant="h3" mb={2} textTransform="capitalize" align="center">
                        {docTitle}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container  alignItems="center" justifyContent="center" mb={2} mt={2}>
                <Grid item xs={12} md={7} >
                    <Box component="img" alt="Detecting Login Anomalies in Microsoft 365" src={headerImage} sx={{ maxWidth: "100%"}} />
                </Grid>
            </Grid>   
            <Grid container justifyContent="center" alignItems="center" mb={5} mt={5}>
                <Grid item xs={12} md={6} textAlign="center">
                    <Typography variant="h5" mb={2} textTransform="capitalize" align="center">
                        Why rapid detection and response matter even when you follow best practices and enable 2-Factor Authentication. 
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" mb={5} mt={5}>
                <Grid item xs={12} md={6} >
                    <Typography variant="body2" color="black" paragraph>
                        When it comes to securing Microsoft 365 for Business, step one is enabling multi-factor authentication. This is a very important step, but there are a number of ways to exploit a user account by bypassing MFA or exploiting users to gain access with MFA. 
                    </Typography>
                    <Typography variant="h5" color="primary" paragraph>
                        Hackers who publish phishing kits are beginning to add multi-factor authentication bypassing capabilities to their software.
                    </Typography>
                    <Typography variant="body2" color="black" paragraph>
                        According to security researchers at Proofpoint hacker kits being made available online are providing transparent reverse proxies that intercept traffic between the victim and the destination, allowing hackers to steal session cookies and gain access to accounts.
                    </Typography>
                    <Typography variant="body2" color="black" paragraph>
                        This is all transparent to the user and online service companies have no way to detect when an exploit like this occurs.
                    </Typography>
                    <Typography variant="body2" color="black" paragraph>
                        Once the hacker has access to the account, they can update credentials, modify MFA settings, steal information, and infect systems with ransomware. 
                    </Typography>
                    <Typography variant="h5" color="primary" paragraph>
                        They are easy to deploy, free to use and have proven effective at evading detection.
                    </Typography>
                    <Typography variant="h6" color="black" paragraph>
                        How does TrawlAI help? 
                    </Typography>
                    <Typography variant="body2" color="black" paragraph>
                        We collect log information and other data points available and build a profile of individual users and their company so that we can detect when an attack like this is successful. By analyzing and trending the frequency of logins and locations, the number of failed sign-ins, what users are accessing and from where, and other key items we know when a successful authentication should not have happened.
                    </Typography>
                    <Typography variant="body2" color="black" paragraph>
                        When an anomaly is detected, we immediately notify you so you can validate the data, force log outs, and reset credentials for the exploited user. We analyze logins and look for anomalies every minute so you can act quickly to prevent damage to your user accounts and data. 
                    </Typography>
                    <List >
                        <ListItem disablePadding>
                            <ListItemButton >
                                <ListItemIcon>
                                    <Verified color="success" />
                                </ListItemIcon>
                                <ListItemText   primary={
                                    <Fragment>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            Detect anomalous logins to your user accounts.
                                        </Typography>
                                </Fragment>
                                }/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                            <ListItemIcon>
                                <Verified color="success" />
                            </ListItemIcon>
                            <ListItemText primary={
                                <Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        Get notified when administrative actions are taken within your tenant. 
                                    </Typography>
                                </Fragment>
                                } />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                            <ListItemIcon>
                                <Verified color="success" />
                            </ListItemIcon>
                            <ListItemText primary={
                                <Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        Detect user activity anomalies that may indicate they are acting against the tenant owner. 
                                    </Typography>
                                </Fragment>
                                } />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Typography variant="body2" color="black" paragraph mt={2}>
                        TrawlAI goes beyond the traditional event log management service. We retain logs for at least a year and trend data for 2 years. 
                    </Typography>
                    <Typography variant="body2" color="black" paragraph>
                        To learn more about TrawlAI and <Link color="darkgreen.main" href="/products/ms365">protection for Microsoft 365</Link>:
                    </Typography>

                    <Typography variant="body2" color="black" paragraph>
                        Reach out today using our <Link color="darkgreen.main" href="/contact-us">contact form</Link> or register <Link color="darkgreen.main" href="https://app.trawlai.com/sign-up" target="_blank">here</Link> for a free trial
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    </>
  );
}

export default LoginAnomalies;
