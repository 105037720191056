import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {  createTheme, ThemeProvider } from '@mui/material/styles';
import colors from "assets/theme/base/colors";
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {Link} from 'react-router-dom';
import workSpace from 'assets/images/illustrations/Google-Workspace-montage.jpg';

const defaultTheme = createTheme({
    palette: {...colors},
    typography: {
      button: {
        textTransform: 'none'
      },
    },
    img: {
        filter: "brightness(50%)",
    },
}); 

// Intelligently detecting unauthorized use and access in your business applications
  
export default function Workspace() {
  useEffect(() =>{
    document.title = 'Google Workspace Cloud Security';
    window.scrollTo(0,0)
   })
  return (
     
     
    <Box
    minHeight="40rem"
    width="100%"
    variant="gradient"
    bgcolor="darkgreen.main"
    sx={{
      backgroundSize: "cover",
      backgroundPosition: "top",
      display: "grid",
      placeItems: "center",
    }}
  >
   <Grid container maxWidth="lg" spacing={2} p={2} mt={2} sx={{ minHeight: "370px"}} >
    <Grid item xs={12} md={6} p={2}>
      <Typography variant="h2" color="white.main" fontWight='fontWeightMedium' mt={4}>
        Intelligent Security for Google Workspace.
      </Typography>
      <Typography variant="h4" color="white.main">
        Beta Coming Soon
      </Typography>
      <Link to="/contact-us" >
        <Button 
            sx={{ marginTop: '50px'}}
            id="learnmore-button"
            disableElevation
            color="light"
            key="learnmore"
            variant="contained" 
            size="lg">
                <Typography sx={{ fontSize: "1.3em" }} color="black.main">Contact Us For Availability</Typography>
        </Button>
      </Link>
    </Grid>
    <Grid item xs={0} md={6} >
          <Box  style={{
            backgroundImage: `url(${workSpace})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "100%",
          }}> 
            
          </Box>
        </Grid>
   </Grid>
  </Box>


  );
}


