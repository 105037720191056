import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import colors from "assets/theme/base/colors";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';


const defaultTheme = createTheme({
  palette: {...colors},
 });

 
const tiers = [
  {
    title: 'Long Term Storage',
    level: 'SMB',
    price: '7',
    description: [
      'All Standard Services',
      'Up to 10 admin users',
      '3 year data and log storage',
      '5 year trend storage',
      'Email & phone support',
    ],
    buttonText: 'Start Now',
    buttonVariant: 'outlined',
    buttonHref: 'https://app.trawlai.com/sign-up'
  },
  {
    title: 'Standard',
    subheader: 'Free Trial',
    level: 'Standard',
    price: '3',
    description: [
      'Complete Microsoft 365 Protection',
      'Limited custom reports',
      'Up to 5 admin users',
      'Email support',
      '1 year data and log storage',
      '3 year trend storage',
    ],
    buttonText: 'Start now for Free',
    buttonVariant: 'contained',
    buttonHref: 'https://app.trawlai.com/sign-up'
  },
  {
    title: 'Enterprise',
    level: 'Enterprise',
    price: '12',
    description: [
      'Early access to new features',
      'Unlimited admin users',
      'Custom reporting',
      'Email & phone support',
      '7 year trend, data, and log storage',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
    buttonHref: 'https://trawlai.com/contact-us'
  },
];

export default function Pricing() {
  return (
    <ThemeProvider theme={defaultTheme} >
      <Container
        id="pricing"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4" sx={{ color: 'text.primary' }}>
            Pricing
          </Typography>
          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            Our MSP partners enjoy up to a 30% discount from retail pricing.
          </Typography>
        </Box>
        <Grid
          container
          spacing={3}
          sx={{ alignItems: 'center', justifyContent: 'center' }}
        >
          {tiers.map((tier) => (
            <Grid
              item
              key={tier.level}
              xs={12}
              sm={tier.level === 'Standard' ? 12 : 6}
              md={4}
            >
              <Card
                sx={[
                  {
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4,
                  },
                  tier.level === 'Standard' &&
                    ((theme) => ({
                      border: 'none',
                      background: 'linear-gradient( #07331c, #122418)',
                      boxShadow: `0 8px 12px hsla(210, 98%, 42%, 0.2)`,
                    
                    })),
                ]}
              >
                <CardContent>
                  <Box
                    sx={[
                      {
                        mb: 1,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 2,
                      },
                      tier.level === 'Standard'
                        ? { color: 'grey.100' }
                        : { color: '' },
                    ]}
                  >
                    <Typography component="h3" variant="h6">
                      {tier.title}
                    </Typography>
                    {tier.level === 'Standard' && (
                      <Chip
                        icon={<AutoAwesomeIcon />}
                        label={tier.subheader}
                        size="small"
                        sx={{
                          borderColor: 'hsla(220, 60%, 99%, 0.3)',
                          backgroundColor: 'hsla(220, 60%, 99%, 0.1)',
                          '& .MuiChip-label': {
                            color: 'hsl(0, 0%, 100%)',
                          },
                          '& .MuiChip-icon': {
                            color: 'trawlgreen.light',
                          },
                        }}
                      />
                    )}
                  </Box>
                  <Box
                    sx={[
                      {
                        alignItems: 'baseline',
                      },
                      tier.level === 'Standard'
                        ? { color: 'grey.50' }
                        : { color: null },
                    ]}
                    justifyContent="center"
                  >
                    <Typography component="h3" variant="h2">
                      ${tier.price}
                    </Typography>
                    <Typography sx={{ fontStyle: 'italic'}}>
                      &nbsp; per user/month
                    </Typography>
                  </Box>
                  <Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
                  {tier.description.map((line) => (
                    <Box
                      key={line}
                      sx={{ py: 1, display: 'flex', gap: 1.5, alignItems: 'center' }}
                    >
                      <CheckCircleRoundedIcon
                        sx={[
                          {
                            width: 20,
                          },
                          tier.level === 'Standard'
                            ? { color: 'trawlgreen.main' }
                            : { color: 'trawlgreen.focus' },
                        ]}
                      />
                      <Typography
                        variant="subtitle2"
                        component={'span'}
                        sx={[
                          tier.level === 'Standard'
                            ? { color: 'grey.50' }
                            : { color: null },
                        ]}
                      >
                        {line}
                      </Typography>
                    </Box>
                  ))}
                </CardContent>
                <CardActions>
                  <Button href={tier.buttonHref} fullWidth color="trawlgreen" variant={tier.buttonVariant}>
                    {tier.level === 'Standard' ? <Typography color="grey.100" >{tier.buttonText}</Typography>: <Typography>{tier.buttonText}</Typography>}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
