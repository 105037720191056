import { useState, useEffect } from 'react';
import {  createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';


export default function StatItemTwo() {
    return (
                    <Box 
                        minHeight="30rem"
                        width="100%"
                        bgcolor="darkgreen.main"
                        variant="gradient"
                        sx={{
                            backgroundSize: "cover",
                            backgroundPosition: "top",
                            display: "grid",
                            placeItems: "center",
                            background: '#0f4d2d',
                        }}
                    >
                        <Grid container maxWidth="lg" spacing={0} ml={2} >
                            <Grid item xs={2} />
                            <Grid item xs={8} sx={{ placeItems: "center" }}>
                                <Typography variant="h4" color="grey.200"><Typography variant="h4" color="warning.main" display="inline">82%</Typography>  
                                {" "} of breaches involved data stored in the cloud - public, private, or multiple encironments.</Typography>
                                <Typography  variant="caption" color="grey.500" sx={{ fontStyle: 'italic', mt: 4 }}>* IBM's Cost of a Data Breach Report</Typography>
                            </Grid>
                        </Grid> 
                    </Box>
    )
}    