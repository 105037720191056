import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import Icon from "@mui/material/Icon";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from  "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export default function ContactInline() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src='https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
        // @TS-ignore
        if (window.hbspt) {
            // @TS-ignore
            window.hbspt.forms.create({
              portalId: "21872696",
              formId: "3e8d1637-816a-43c8-9f28-b7f08ae496fd", 
              target: '#hubspotForm'
            })
        }
    });
  }, []);
  return (
    <Box component="section" py={{ xs: 3, md: 12 }} id="contact" >
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={5}>
            <Typography variant="h3" my={1} color="text" pb={5}>
              For more information
            </Typography>
            <Typography variant="body1" color="text" mb={4}>
              Complete the contact form and we'll respond with an email and answer any questions you may have. If you'd like to get started on a free 30 day trial {" "} 
                <Link
                  href="https://app.trawlai.com/sign-up"
                  target="_blank"
                >
                  subscribe here <ArrowForwardIcon sx={{ fontSize: "small" }}/>
              </Link>
            </Typography>
            <Typography
              component="a"
              href="https://trawlai.com"
              target="_blank"
              variant="body2"
              color="info"
              fontWeight="regular"
              sx={{
                width: "max-content",
                display: "flex",
                alignItems: "center",

                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: "translateX(3px)",
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },

                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: "translateX(6px)",
                },
              }}
            />
            
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }} >
            <Box
              color="white"
              bgColor="white"
              variant="gradient"
              shadow="lg"
              opacity={1}
              p={2}
              alignContent="center"
              sx={{ height: {xs: '500px', md: '410px'} }} 
            >
              <div id="hubspotForm" />
          </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

