import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import colors from "assets/theme/base/colors";
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';

const defaultTheme = createTheme({
  palette: {...colors},
  typography: {
    button: {
      textTransform: 'none'
    },
  },
 });

const StyledBox = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  width: '100%',
  height: 400,
  marginTop: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  outline: '1px solid',
  boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
  backgroundImage: `url(${'/static/images/TrawlAI-MS365-Dashboard-FullScreen.png'})`,
  outlineColor: 'hsla(220, 25%, 80%, 0.5)',
  backgroundSize: 'cover',
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(10),
    height: 650,
  },
  
}));

export default function Hero() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        id="hero"
        sx={(theme) => ({
          width: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(100, 100%, 90%), transparent)',
          
        })}
      >
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: { xs: 14, sm: 20 },
            pb: { xs: 8, sm: 6 },
          }}
        >
          <Stack
            spacing={2}
            useFlexGap
            sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
          >
            <Typography
              variant="h1"
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center',
                fontSize: 'clamp(3rem, 10vw, 3.5rem)',
                textAlign: "center",
                color: 'grey.800'
              }}
            >
              Multi-tenant Security for &nbsp;
              <Typography
                component="span"
                variant="h1"
                sx={(theme) => ({
                  fontSize: 'inherit',
                  color: 'trawlgreen.main',
                
                })}
              >
                MS 365
              </Typography>
            </Typography>
            <Typography
              sx={{
                textAlign: 'center',
                color: 'text.secondary',
                width: { sm: '100%', md: '80%' },
              }}
              variant="h6"
            >
              Protect against <Typography variant="h6" component="span" color="darkgreen.main" sx={{ fontWeight: "bold", fontStyle: "italic"}}>account takeover, supply chain attacks, MFA bypass</Typography>, and more, with <img src="/static/images/trawl logo green ai 2023.png" width="75px" />'s state-of-the-art machine-learning platform. 
            </Typography>
            
          </Stack>
          <StyledBox id="image" />
        </Container>
      </Box>
      </ThemeProvider>
  );
}
