import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';


export default function FeatureComparison() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '50%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h3">
            Feature Comparison
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.800' }}>
            See how <img src="/static/images/trawl logo green ai 2023.png" width="60px" /> stacks up against other Microsoft 365 security products.
          </Typography>
        </Box>
        <Box justifyContent="center">
            <img src="/static/images/TrawlAI-Feature-Comparison.png" />
        </Box>

      </Container>
    </Box>
  );
}
