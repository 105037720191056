import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';

const items = [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: 'Security in minutes',
    description:
      'Sign up and add Microsoft 365 tenants in just a few steps taking less than three minutes to complete.',
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: 'Easily customizable notifications',
    description:
      'Enable and disable alert triggers and notifications with the click of a button.',
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Everything is included',
    description:
      'Our Microsoft 365 service provide complete protection with options for data retention. Get protected without limits, for a great value.',
  },
  {
    icon: <AutoFixHighRoundedIcon />,
    title: 'Innovative intelligence',
    description:
      'Our unique perspective on cloud security provides our customers and partners with an agile platform that recognizes changes in attack methods quickly.',
  },
  {
    icon: <SupportAgentRoundedIcon />,
    title: 'Alert notifications',
    description:
      "Get alerted immediately when breaches occur, changes in usage patterns indicate a user has been compromised, or user anomalies indicate they are working against the tenant's interest.",
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Reporting & Charts',
    description:
      'Visualize data from user activities and internal processes, get customized reports to help your tenants reduce costs and validate employee activity.',
  },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: 'hsl(220, 30%, 2%)',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '50%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            Highlights
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            Explore why our product stands out: simplicity, agility, adaptability, 
            user-friendly design, and an unrivaled, innovative take on cybersecurity. 
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  color: 'inherit',
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'hsla(220, 25%, 25%, .3)',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                  boxShadow: 'none',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography gutterBottom sx={{ fontWeight: 'medium' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
