import { useState, useEffect } from 'react';
import {  createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import colors from "assets/theme/base/colors";
import Icon from "@mui/material/Icon";
import {Link} from 'react-router-dom';
import ContactInline from "components/ContactForm/ContactForm"
import IndexPageHeader from './Components/IndexHeader';
import IndexPageintro from './Components/IndexPageIntro';
import StatItemOne from './Components/StatItemOne';
import StatItemTwo from './Components/StatItemTwo';
import StatItemThree from './Components/StatItemThree';
import WhatCanWeDetect from './Components/WhatCanWeDetect'; 
import CustomerSuccessStories from './Components/CustomerSuccessStories';
import HowItWorks from './Components/HowItWorks';

const defaultTheme = createTheme({
    palette: {...colors},
    typography: {
      button: {
        textTransform: 'none'
      },
    },
    img: {
        filter: "brightness(50%)",
    },
}); 



function BasicCard({ color, icon, title, description, direction, small }) {
    return (
        <Box lineHeight={1} p={direction === "center" ? 2 : 0} textAlign={direction}  >
        {typeof icon === "string" ? (
          <Typography
            display="block"
            fontSize="4rem"
            color={color}
            textGradient
          >
            {" "}
            <Icon>{icon}</Icon>{" "}
          </Typography>
        ) : (
          icon
        )}
        <Typography
          display="block"
          variant="5"
          fontWeight="bold"
          mt={direction === "center" ? 1 : 2}
          mb={1.5}
        >
          {title}
        </Typography>
        <Typography
          display="block"
          variant={small ? "button" : "body2"}
          color="text"
          pr={direction === "left" ? 6 : 0}
          pl={direction === "right" ? 6 : 0}
        >
          {description}
        </Typography>
      </Box>
    );
  }

export default function Index() {
  useEffect(() => {
    window.scrollTo(0,0);
    document.title = 'Security Intelligence for Public Cloud';

  }, [])
    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container id="home">
                <Grid item xs={12} >
                    <IndexPageHeader />
                </Grid>
            </Grid>
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <IndexPageintro />
                </Grid>
            </Grid>
            <Grid container >
                <Grid item xs={12} >
                    <StatItemOne />
                </Grid>
            </Grid>
            <Grid container spacing={2} id="howitworks">
                    <HowItWorks />
            </Grid>
            <Grid
                container
                item
                xs={12}
                lg={6}
                justifyContent="center"
                sx={{ mx: "auto", textAlign: "center" }}
                mb={4}
            >
                <Typography variant="h2" mb={1} mt={10} color="grey.900">What Our Customers Have To Say </Typography>   
            </Grid>
            <CustomerSuccessStories />
            <Grid container spacing={2}>
                <Grid item xs={12} >
                    <WhatCanWeDetect />
                </Grid>
            </Grid>
            <StatItemTwo />
            <Grid container maxWidth="lg" spacing={2} justifyContent="center" sx={{ mx: "auto" }} pb={2} >
                    <ContactInline />
            </Grid>
        </ThemeProvider>
    )
}
