import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {  createTheme, ThemeProvider } from '@mui/material/styles';
import colors from "assets/theme/base/colors";
import Button from '@mui/material/Button';
import {Link} from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ms365 from 'assets/images/illustrations/Microsoft-365-copy.jpg';

const defaultTheme = createTheme({
    palette: {...colors},
    typography: {
      button: {
        textTransform: 'none'
      },
    },
    img: {
        filter: "brightness(50%)",
    },
}); 

// Intelligently detecting unauthorized use and access in your business applications
  
export default function Microsoft365() {
  useEffect(() =>{
    document.title = 'Microsoft 365 Cloud Security';
    window.scrollTo(0,0)
   })
  return (
     <>
      <Box
        minHeight="30rem"
        width="100%"
        variant="gradient"
        bgcolor="darkgreen.main"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Grid container maxWidth="lg" spacing={2} p={2} mt={8} sx={{ minHeight: "350px"}} >
          <Grid item xs={12} md={6} p={2}>
            <Typography variant="h2" color="white.main" fontWight='fontWeightMedium' mt={4}>
              Intelligent Security for Microsoft 365.
            </Typography>
            <Link to="https://app.trawlai.com/sign-up" target="_blank">
              <Button 
                  sx={{ marginTop: '50px'}}
                  id="learnmore-button"
                  disableElevation
                  color="light"
                  key="learnmore"
                  variant="contained" 
                  size="lg">
                      <Typography sx={{ fontSize: "1.3em" }} color="black.main">Get started today</Typography>
              </Button>
            </Link>
          </Grid>
          <Grid item xs={0} md={6} >
          <Box  style={{
            backgroundImage: `url(${ms365})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100%",
          }}> 
            
          </Box>
        </Grid>
        </Grid>
      </Box>
      <Box
        minHeight="40rem"
        width="100%"
        variant="gradient"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
          background: '#0f4d2d'
        }}
      >
        <Grid container maxWidth="lg" spacing={2}  mb={2}>
          <Grid item xs={12} md={5} sx={{ alignItems: "right"}}  p={2} mt={2} ml={2}>
            <Typography variant="h4" sx={{ fontWeight: 700 }} color="grey.100">
              Why additional security?
            </Typography>
            <Typography variant="h5" color="grey.300" mt={2}>
              While Microsoft 365 offers productivity and collaboration to its users, there are some key security concerns that can be difficult to navigate, and the
              included security features require expertise to configure and are time consuming to maintain. 
            </Typography>
            <Typography variant="h5" color="grey.300" mt={4}>
              Changes and improvements to the service require re-evaluation of security settings, and for a small company without dedicated personnel or a Managed
              Services Provider maintaining dozens of tenants this can often lead to unnecessary exposure to attacks.
            </Typography>
          </Grid>
          <Grid md={1} />
          <Grid item xs={12} md={5} sx={{ backgroundColor: "white" }}>
            <Box
              minHeight="100%"
              bgcolor="darkgreen.focus" 
              p={4}

              >
                <Typography variant="h6" color="grey.300">
                  Data Exposure Risks
                </Typography>
                <Typography variant="body2" color="grey.400">
                  <List sx={{ listStyleType: 'disc', pl: 6 }} dense>
                    <ListItem sx={{ display: 'list-item' }}>Sharing Flexibility - accidental or malicious sharing of sensitive information</ListItem>
                    <ListItem sx={{ display: 'list-item' }}>Weak Permissions - Overly broad permissions assigned to users</ListItem>
                    <ListItem sx={{ display: 'list-item' }}>Third-Party Apps - Integrations might introduce vulnerabilities</ListItem>
                  </List>
                </Typography>
                <Typography variant="h6" color="grey.300">
                  Credential Theft
                </Typography>
                <Typography variant="body2" color="grey.400">
                  <List sx={{ listStyleType: 'disc', pl: 6 }} dense>
                    <ListItem sx={{ display: 'list-item' }}>Phishing Attacks - Emails that trick users into revealing credentials</ListItem>
                    <ListItem sx={{ display: 'list-item' }}>Weak Passwords - Users tend to chose weak, easily guessable passwords</ListItem>
                    <ListItem sx={{ display: 'list-item' }}>Malware and Ransomeware - Steal credentials and encrypt data for ransom</ListItem>
                  </List>
                </Typography>
            </Box>
          </Grid>
       </Grid>
      </Box>
      <Box
        minHeight="40rem"
        width="100%"
        variant="gradient"
        bgcolor="grey.100"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
       <Grid container maxWidth="lg" mb={5} mt={8}>
          <Grid item xs={12} md={5} sx={{ backgroundColor: "white" }}>
            <Box
              minHeight="100%"
              bgcolor="darkgreen.focus" 
              p={4}

              >
                <Typography variant="h6" color="grey.300">
                  Privilege Abuse
                </Typography>
                <Typography variant="body2" color="grey.400">
                  <List sx={{ listStyleType: 'disc', pl: 6 }} dense>
                    <ListItem sx={{ display: 'list-item' }}>Excessive Privileges - Unnecessary admin-level access assigned to users</ListItem>
                    <ListItem sx={{ display: 'list-item' }}>Weak Credentials - Weak passwords or lack of multi-factor authentication (MFA)</ListItem>
                    <ListItem sx={{ display: 'list-item' }}>Misconfigured Security Settins - Improper configuration can leave exploitable vulnerabilities</ListItem>
                  </List>
                </Typography>
                <Typography variant="h6" color="grey.300">
                  Additional Concerns
                </Typography>
                <Typography variant="body2" color="grey.400">
                  <List sx={{ listStyleType: 'disc', pl: 6 }} dense>
                    <ListItem sx={{ display: 'list-item' }}>Federated Identity Management - accidental or malicious sharing of sensitive information</ListItem>
                    <ListItem sx={{ display: 'list-item' }}>Data Redidency- Overly broad permissions assigned to users</ListItem>
                  </List>
                </Typography>
            </Box>
          </Grid>
          <Grid md={1} />
          <Grid item xs={12} md={6} sx={{ alignItems: "right"}} p={2}>
            <Typography variant="h4" sx={{ fontWeight: 700 }} color="trawlgrey">
              What about insider threats?
            </Typography>
            <Typography variant="h5" sx={{ fontSize: "1.4em"}} color="trawlgrey" mt={2}>
              Unintential actions, through accident or negligence, can potentially compromise data, disrupt operations, and cause direct harm to your company or client.
            </Typography>
            <Typography variant="h5" sx={{ fontSize: "1.4em"}} color="trawlgrey" mt={4} >
              A malicious insider can steal data, sabotage systems, and commit fraud, or collude with an outside to carry out a malicious attack in exchange for a bribe.
            </Typography>
            <Typography variant="h5" sx={{ fontSize: "1.4em"}} color="trawlgrey" mt={4} >
              By implementing a layered security approach, and fostering a culture of security awareness, organizations can significanly reduce the risk of insider threats within
              their Microsoft 365 environment.
            </Typography>
          </Grid>
       </Grid>
       <Grid container maxWidth="lg" mb={5} p={2}>
        <Grid item xs={12}>
            <Link to="https://app.trawlai.com/sign-up" target="_blank" >
                  <Button 
                      sx={{ marginTop: '50px', marginRight: '50px'}}
                      id="learnmore-button"
                      disableElevation
                      color="darkgreen"
                      key="learnmore"
                      variant="contained" 
                      size="lg">
                          <Typography sx={{ fontSize: "1.3em" }} color="white.main">Get started today for free</Typography>
                  </Button>
              </Link>
            <Link to="/contact-us">
                  <Button 
                      sx={{ marginTop: '50px'}}
                      id="learnmore-button"
                      color="light"
                      key="learnmore"
                      variant="text" 
                      size="lg">
                          <Typography sx={{ fontSize: "1.3em", textDecoration: 'underline' }} color="black.main">Or contact us for more information</Typography>
                  </Button>
            </Link>
        </Grid>
       </Grid>
      </Box>
  </>

  );
}


