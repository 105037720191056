import {useState, useEffect} from 'react';
import { styled, createTheme, ThemeProvider, alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import trawlLogo from 'assets/images/logos/trawl dark logo med 2022.png'
import colors from "assets/theme/base/colors";
import { Outlet, Link, useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Person, TableChart, Summarize, Room  } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { ListItemIcon } from '@mui/material';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import LogoutIcon from '@mui/icons-material/Logout';
import Home from '@mui/icons-material/Home';
import ListItemText from '@mui/material/ListItemText';
import ScrollToHashElement from 'components/ScrollToHashElement';
import Footer from "./Footer"

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
  palette: {...colors},
  typography: {
    button: {
      textTransform: 'none'
    },
  },
 });
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 300,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


export default function Dashboard() {
  const [open, setOpen] = useState(true);
   const toggleDrawer = () => {
    setOpen(!open);
  };
  const [anchorElNav, setAnchorElNav] = useState(null); // Mobile view nav menu dropdown
  const [anchorElAbout, setAnchorElAbout] = useState(null);  // About menu
  const [anchorElServe, setAnchorElServe] = useState(null); // Who we serve menu
  const [anchorElPlatform, setAnchorElPlatform] = useState(null); // Platform menu

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenAboutMenu = (event) => {
    setAnchorElAbout(event.currentTarget);
  };
  const handleOpenServeMenu = (event) => {
    setAnchorElServe(event.currentTarget);
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  const [anchorEl, setAnchorEl] = useState(null);
  const openAbout = Boolean(anchorElAbout);
  const openServe = Boolean(anchorElServe);
  const openPlatform = Boolean(anchorElPlatform);


  const handleClickPlatform = (event) => {
    setAnchorElPlatform(event.currentTarget);
  }
  const handleClickAbout = (event) => {
    setAnchorElAbout(event.currentTarget);
  }
  const handleClickServe = (event) => {
    setAnchorElServe(event.currentTarget);
  }
  const handleClosePlatform = () => {
    setAnchorElPlatform(null);
  }
  const handleCloseAbout = () => {
    setAnchorElAbout(null);
  };
  const handleCloseServe = () => {
    setAnchorElServe(null);
  }
  const nav = useNavigate();
  const Logout = () => {
    AuthService.logout();
    nav("/sign-in")
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <ScrollToHashElement />
      <Container sx={{ display: 'flex' }} maxWidth={false} disableGutters >
        <CssBaseline />
        <AppBar color="darkgreen" position="fixed" elevation={0} open={open}>
          <Toolbar>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ ml: '15%'}}
            >
              <Link to="/#home" >
                <img src={trawlLogo} width="180px" />
              </Link>
            </Typography>
            <Box sx={{  flexGrow: 1, display: { xs: 'flex', md: 'none' }}} >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon color="light" />
              </IconButton>
              {/* Dropdown menu */}
              <Menu
                PaperProps={{ sx: {width: '75%'}}}
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
            >            
                <Typography textAlign="left" ml={1}>Product & Roadmap</Typography>
                <Divider p={1} component="li" />
                <MenuItem component={Link} to="/products/ms365" key={"ms365"} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Microsoft 365</Typography>
                </MenuItem>
                <MenuItem component={Link} to="/products/azure" key={"azure"} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Microsoft Azure</Typography>
                </MenuItem>
                <MenuItem  component={Link} to="/products/aws" key={"aws"} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Amazon AWS</Typography>
                </MenuItem>
                <MenuItem  component={Link} to="/products/workspace" key={"workspace"} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Google Workspace</Typography>
                </MenuItem>                
                <MenuItem  component={Link} to="/products/gcp" key={"gcp"} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Google GCP</Typography>
                </MenuItem>
                <MenuItem  component={Link} to="/products/siem" key={"siem"} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">SIEM</Typography>
                </MenuItem>
                <Divider component="li" />
                <MenuItem component={Link} to="/about" key={"about"} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">About Trawl</Typography>
                </MenuItem>
                <MenuItem component={Link} to="/contact-us" key={"contact"} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Contact Us</Typography>
                </MenuItem>
                <Divider component="li" />
                <MenuItem component={Link} to="/managed-partners" key={"msps"} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">For MSPs</Typography>
                </MenuItem>
                <MenuItem component={Link} to="/smb" key={"smbs"} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">For SMBs</Typography>
                </MenuItem>
                <Divider component="li" />
                <MenuItem component={Link} to="/blog/index" key={"smbs"} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Blog</Typography>
                </MenuItem>
                <Divider component="li" />

                <MenuItem >
                    <Button
                        component={Link}
                        to="https://app.trawlai.com/sign-up"
                        target="_blank"
                        id="login-button"
                        disableElevation
                        color="trawlgreen"
                        key="signup"
                        variant="contained"
                        size="large"
                        fullWidth
                      >
                        <Typography color="grey.200">Get Started Today</Typography>
                      </Button>
                </MenuItem>
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} ml={8}> {/* Main NavBar Menu */}
              <Button
                  id="home-button"
                  component={Link}
                  color="light"
                  key="home"
                  aria-controls={openPlatform ? 'home-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openPlatform ? 'true' : undefined}
                  disableElevation
                  onClick={handleClickPlatform}
                  endIcon={<KeyboardArrowDownIcon />}
                  sx={{ml: '50px'}}
                  >
                  Products & Roadmap
              </Button>
              <StyledMenu
                  id="home-menu"
                  MenuListProps={{
                    'aria-labelledby': 'home-menu',
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  anchorEl={anchorElPlatform}
                  open={openPlatform}
                  onClose={handleClosePlatform}
                >


                  <MenuItem  component={Link} to="/products/ms365"  onClick={handleClosePlatform} disableRipple>
                      <ListItemText>Microsoft 365</ListItemText> <Typography display="inline" fontWeight="bold" color="trawlgreen.main" variant="caption">Live!</Typography>
                  </MenuItem>
                  <MenuItem  component={Link} to="/products/azure" onClick={handleClosePlatform} disableRipple>
                      <ListItemText>Microsoft Azure</ListItemText> <Typography display="inline" variant="caption" fontStyle="italic" color="error.main">Coming Soon</Typography>
                  </MenuItem>
                  <MenuItem  component={Link} to="/products/aws"  onClick={handleClosePlatform} disableRipple>
                      <ListItemText>Amazon AWS </ListItemText><Typography display="inline" fontWeight="bold" color="secondary.main" fontStyle="italic" variant="caption">Beta</Typography>
                  </MenuItem>
                  <MenuItem   component={Link} to="/products/workspace" onClick={handleClosePlatform} disableRipple>
                      Google Workspace
                  </MenuItem>
                  <MenuItem   component={Link} to="/products/gcp" onClick={handleClosePlatform} disableRipple>
                      Google Cloud Platform
                  </MenuItem>
                  <MenuItem   component={Link} to="/products/siem" onClick={handleClosePlatform} disableRipple>
                      <ListItemText>SIEM</ListItemText><Typography display="inline" fontWeight="bold" color="secondary.main" fontStyle="italic" variant="caption"> Beta</Typography>
                  </MenuItem>
                </StyledMenu>

              <Typography marginRight={3} />
              <Button
                  id="about-button"
                  aria-controls={openAbout ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openAbout ? 'true' : undefined}
                  disableElevation
                  onClick={handleOpenAboutMenu}
                  endIcon={<KeyboardArrowDownIcon />}
                  color="light"
                  key="about"
                >
                  About
              </Button>
              <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  anchorEl={anchorElAbout}
                  open={openAbout}
                  onClose={handleCloseAbout}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}}
                  transformOrigin={{ vertical: 'top', horizontal: 'left'}}
                >
                 
                  <MenuItem component={Link} to="about" onClick={handleCloseAbout} disableRipple>
                      About TrawlAI
                  </MenuItem>
                  <MenuItem component={Link} to="/contact-us"  onClick={handleCloseAbout} disableRipple>
                      Contact Us
                  </MenuItem>
              </StyledMenu>
              <Typography marginRight={3} />
              <Button
                  id="serve-button"
                  aria-controls={openServe ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openServe ? 'true' : undefined}
                  disableElevation
                  onClick={handleOpenServeMenu}
                  endIcon={<KeyboardArrowDownIcon />}
                  color="light"
                  key="serve"
                >
                  Who we serve
                </Button>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  anchorEl={anchorElServe}
                  open={openServe}
                  onClose={handleCloseServe}
                >

                  <MenuItem component={Link} to="/managed-partners" onClick={handleCloseServe} disableRipple>
                      Managed Services Providers
                  </MenuItem>
                  <MenuItem component={Link} to="/smb"  onClick={handleCloseServe} disableRipple>
                      Business Services & IT Teams
                  </MenuItem>
                </StyledMenu>
              <Typography marginRight={3} />
              <Button
                    id="login-button"
                    disableElevation
                    color="light"
                    key="blog"
                    component={Link} to="/blog/index"
                    sx={{ mr: 5}}
                  >
                    Blog
                </Button>
                <Link to="https://app.trawlai.com/sign-up" target="_blank">
                  <Button
                      id="login-button"
                      disableElevation
                      color="trawlgreen"
                      key="signup"
                      variant="contained"
                    >
                      <Typography color="grey.200">Get Started</Typography>
                    </Button>
                </Link>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Link to="https://app.trawlai.com" target="_blank">
                <Button
              
                      id="login-button"
                      disableElevation
                      color="light"
                      key="login"
                      startIcon={<Person />}
                      sx={{ mr: 5}}
                    >
                      Log In
                  </Button>
                </Link>
                
            </Box>
          </Toolbar>
        </AppBar>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            width: '100%',
            // removed for scroll location -- overflow: 'auto',
          }}
        >
          <Toolbar />
          <Outlet />
          <Footer />

        </Box>

      </Container>
    </ThemeProvider>
  );
}
