import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';

const userTestimonials = [
  {
    avatar: <Avatar alt="S" src="/static/images/avatar/1.jpg" />,
    name: 'President & CEO',
    occupation: 'Landscape Architecture',
    testimonial:
      `TrawlAI not only protects us from account takeover and other attacks, but we can also easily review the activity of outgoing personnel to 
      ensure our intellectual property is secure.`,
  },
  {
    avatar: <Avatar alt="P" src="/static/images/avatar/2.jpg" />,
    name: 'Senior Vice President',
    occupation: 'Medical Research',
    testimonial:
      `We were migrating to Microsoft 365 and our MSP was using TrawlAI to protect our accounts. Prior to enabling 2 step authentication, 
       someone got into my account early on a Sunday morning. Within minutes, the MSP was notified and locked my account to prevent access
       to our research data.`,
  },
  {
    avatar: <Avatar alt="B" src="/static/images/avatar/3.jpg" />,
    name: 'Principal',
    occupation: 'Managed IT Services',
    testimonial:
      `"TrawlAI provides a simple way to secure our customers and report on individual activity, easily switching between tenants. 
        It takes less than a minute to add a tenant to the platform, with plenty of predefined alerts.
        "`,
  },
 
];



export default function Testimonials() {


  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" sx={{ color: 'text.primary' }}>
          Testimonials
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          See what our customers love about our product and how we've helped them secure their Microsoft 365 services. 
          
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
                p: 1,
              }}
            >
              <CardContent>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  pr: 2,
                }}
              >
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
               
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
