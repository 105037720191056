import { useState, useEffect } from 'react';
import {  createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import colors from "assets/theme/base/colors";
import Icon from "@mui/material/Icon";
import {Link} from 'react-router-dom';
import Blog from "components/Blog/Blog";


const defaultTheme = createTheme({
    palette: {...colors},
    typography: {
      button: {
        textTransform: 'none'
      },
    },
    img: {
        filter: "brightness(50%)",
    },
}); 


function BasicCard({ color, icon, title, description, direction, small }) {
    return (
        <Box lineHeight={1} p={direction === "center" ? 2 : 0} textAlign={direction}  >
        {typeof icon === "string" ? (
          <Typography
            display="block"
            fontSize="4rem"
            color={color}
            textGradient
          >
            {" "}
            <Icon>{icon}</Icon>{" "}
          </Typography>
        ) : (
          icon
        )}
        <Typography
          display="block"
          variant="5"
          fontWeight="bold"
          mt={direction === "center" ? 1 : 2}
          mb={1.5}
        >
          {title}
        </Typography>
        <Typography
          display="block"
          variant={small ? "button" : "body2"}
          color="text"
          pr={direction === "left" ? 6 : 0}
          pl={direction === "right" ? 6 : 0}
        >
          {description}
        </Typography>
      </Box>
    );
  }

export default function BlogIndex() {
    return (
        <ThemeProvider theme={defaultTheme}>
            <Box mt={5} mb={10}>
                <Blog />
            </Box>
        </ThemeProvider>
    )
}
