import { useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
// Intelligently detecting unauthorized use and access in your business applications
import colors from "assets/theme/base/colors";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Link from '@mui/material/Link';
import headerImage from 'assets/images/blogs/SharePoint.jpg'
import Box from '@mui/material/Box';
import spLogImage from 'assets/images/blogs/sharepoint-file-access-hack.jpg';
import Breadcrumbs from '@mui/material/Breadcrumbs';

const defaultTheme = createTheme({
    palette: {...colors},
    typography: {
      button: {
        textTransform: 'none'
      },
    },
    
  }); 

export default function SilentDataExfiltration() {
    const docTitle  = 'Methods discovered to steal data while evading detection';
    useEffect(() =>{
        document.title = docTitle;
     })
  return (
    <>

        <Container>
            <Grid container justifyContent="center" alignItems="center" mb={5} mt={10}>
                <Grid item xs={12} md={9} textAlign="center">
                    <Typography variant="h3" mb={2} textTransform="capitalize" align="center">
                        {docTitle}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container  alignItems="center" justifyContent="center" mb={2} mt={3}>
                <Grid item xs={12} md={7} >
                    <Box component="img" alt="SharePoint silent data exfiltration" src={headerImage} sx={{ maxWidth: "100%"}} />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" mb={5} mt={10}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h5" textTransform="capitalize" align="center">
                        Attackers are accessing files using the "Open in App" feature to go undetected
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" mb={5} mt={10}>
                <Grid item xs={12} md={6} >
                    <Typography variant="subtitle1" color="black" paragraph>
                        <Link href="https://www.varonis.com/blog/sidestepping-detection-while-exfiltrating-sharepoint-data" target="_blank" color="darkgreen.main">Varonis</Link> recently reported two new techniques that allow users to circumvent audit logs and avoid triggering 'file download' events that would
                        be detected by a Security Information & Event Management (SIEM) system. 
                    </Typography>
                    <Typography variant="subtitle1" color="black" paragraph>
                        The first uses the "open in app" feature of SharePoint. This downloads the file for use in Word or Excel but logs only a 'file access' event. 
                        PowerShell can be used to automate the file downloads, giving the attacker quick acces to sensitive information without detection. 
                    </Typography>
                    <Typography variant="subtitle1" color="black" paragraph>
                        The second technique uses what's called a 'User-Agent' for Microsoft SkyDriveSync to download files or entire SharePoint sites while only logging the
                        access as 'syncing' for the app. 
                    </Typography>
                    <Typography variant="h6" color="black" paragraph>
                        What is a <Link color="darkgreen.main" href="https://en.wikipedia.org/wiki/User_agent" target="_blank">User-Agent</Link>? 
                    </Typography>
                    <Typography variant="subtitle1" paragraph>A user agent is a piece of software that is used for 
                        facilitating interaction with web-based content. Google Chrome, Microsoft Edge, 
                        email clients, are examples of user agents. User agents can be defined manually in software, allowing attackers to fool the 
                        Microsoft service into logging the wrong action to avoid detection.
                    </Typography>
                    <Box component="img" alt="SharePoint silent data exfiltration" src={spLogImage} sx={{ maxWidth: "100%"}} />
                    <Typography variant="h6" color="black" paragraph mt={2}>
                        Why is this an issue?
                    </Typography>
                    <Typography variant="subtitle1" color="black" paragraph>
                        Many companies rely on SharePoint and OneDrive to maintain and share important documents that often contain personally identifyable
                        information (PII), company secrets, and vendor and payroll information. Attackers can use these files to direct attacks at individual employees to get payroll
                        and wire transfer information changed to steal money, or sell the information to other hacking groups.  
                    </Typography>
                    <Typography variant="subtitle1" color="black" paragraph>
                        These new methods further highlight the issue with modern cybersecurity. Prevention techniques are becoming less effective and more SPAM and phishing emails
                        are getting through filters, thanks to Large Language Model (LLM) availability to write better emails. Detection techniques that rely on single events being
                        identified by SIEMs, or a large number of similar events, are becoming less valuable because of the number of ways to hide or disguise actions.
                    </Typography>
                    <Typography variant="h6" color="black" paragraph>
                        How TrawlAI can help
                    </Typography>
                    <Typography variant="subtitle1" color="black" paragraph>
                        At TrawlAI, what we are doing represents a new perspective on cybersecurity. Not just user behaviour anomaly detection, but creating 
                        proprietary data profiles and using innovative techniques to determine when a known or unknown attack vector has been used successfully. 
                    </Typography>
                    <Typography variant="subtitle1" color="black" paragraph>
                        TrawlAI offers an automated security intelligence system to protect your Microsoft 365 tenant from all attack vectors, without complicated
                        configuration. Get started in minutes by creating a user and adding a subscription <Link color="darkgreen.main" href="https://app.trawlai.com/sign-up" target="_blank">here</Link> 
                         {" "} and try it free for 30 days.
                    </Typography>
                   
                </Grid>
            </Grid>
        </Container>
     
    </>
  );
}


