import { Routes, Route } from "react-router-dom"
import NotFound from "./NotFound";
import { Navigate } from "react-router-dom";
import Blog from "./Blog";
import BlogIndex from "./BlogIndex";
import CriticalInfrastructure from "./Posts/critical-infrastructure-supply-chain-attacks";
import LoginAnomalies from "./Posts/detecting-login-anomalies-m-365";
import SilentDataExfiltration from "./Posts/silent-data-exfiltration";
import UberCompromise from "./Posts/uber-aa-email-compromise";


export function BlogRoutes() {
    return (
            <Routes> 
                <Route path="/index" element={<BlogIndex />} />
                <Route path="/detecting-login-anomalies-m-365" element={<LoginAnomalies />} />
                <Route path="/uber-aa-email-compromise" element={<UberCompromise />} />
                <Route path="/critical-infrastructure-supply-chain-attacks" element={<CriticalInfrastructure />} />
                <Route path="/silent-data-exfiltration" element={<SilentDataExfiltration />} />
                <Route path="*" element={<Navigate to="/404" />} />
            </Routes>

    )
}